@import "colors.scss";

.plan-viewer {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .image-zoom-wrapper {
        border: none;
        background-color: $light;

        width: 100%;
        height: 50%;

        margin: 0;
        overflow: hidden;
    }

    .zone-content {
        width: 100%;
        height: 50%;

        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

/* Overwriting Bootstrap colors */

.nav.nav-tabs {
    border-bottom-color: $red;

    > li {
        > a {
            color: $black;
        }

        > a:hover {
            background-color: transparent;
            border-color: transparent;
        }
    }

    > li.active > a,
    > li.active > a:hover {
        color: $red;
        border-color: $red;
        border-bottom-color: $white;
    }
}